<template>
    <div id="page-branch-list">
        <FireAlarm v-show="aiFireAlarmCheck && aiFireAlarmCheck.length == 0"></FireAlarm>
        <div v-show="aiFireAlarmCheck && aiFireAlarmCheck.length > 0" class="vx-card p-6">
            <div class="flex-grow">
                <div class="vx-col md:w-1/4 sm:w-auto mr-4">
                    <label class="vs-input--label">Đơn vị trực thuộc</label>
                    <v-select class="" v-model="selectedOrganizationBranch" :clearable="false"
                              :options="organizationBranchOptions">
                        <span slot="no-options">{{ $t('vSelect.noRowsToShow') }}</span>
                    </v-select>
                </div>
            </div>
            <div class="flex flex-wrap items-center mt-5">
                <div class="flex-grow">
                    <vs-button
                        @click="addAIRes"
                        color="primary"
                        type="border"
                        icon-pack="feather"
                        icon="icon-flag">
                        Thêm sự kiện
                    </vs-button>
                </div>

                <!-- ITEMS PER PAGE -->
                <Pagination
                    :itemList="aiFireAlarm"
                    :currentPage="currentPage"
                    :paginationPageSize="paginationPageSize"
                    :gridApi="gridApi"
                />

                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                          v-model="searchQuery"
                          @input="updateSearchQuery" placeholder="Tìm kiếm..."/>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="aiFireAlarm"
                         :animateRows="true"
                         :pagination="true"
                         :localeText=" {noRowsToShow: this.$t('menuCustomer.noRowsToShow')}"
                         :paginationPageSize="paginationPageSize"
                         :onGridReady="onGridReady"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>

            <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import Pagination from "../../ui-elements/Pagination.vue"
import ErrorFunction from "../error-function/ErrorFunction";
import FireAlarm from './FireAlarmService';

export default {
    components: {
        ErrorFunction,
        AgGridVue,
        vSelect,
        // Cell Renderer
        CellRendererActions,
        Pagination,
        FireAlarm
    },
    data() {
        return {
            aiFireAlarmCheck: null,
            isShowErrorFunction: false,
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: 'id',
                    field: 'id',
                    width: 100
                },
                {
                    headerName: 'Tên sự kiện',
                    field: 'name',
                    width: 120
                },
                {
                    headerName: 'Camera',
                    field: 'cameraName',
                    width: 150
                },
                {
                    headerName: 'Tên chi nhánh',
                    field: 'branchName',
                    width: 100
                },
                {
                    headerName: 'Hành động',
                    field: 'actions',
                    width: 60,
                    cellRendererFramework: 'CellRendererActions',
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererActions,
            },
            aiFireAlarm: [],
            organizationBranchOptions: [],
            selectedOrganizationBranchValue: null,
            cameraOptions: []
        }
    },
    created() {
        this.initScreen()
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateAIFirealarm'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('UPDATE_AI_FIRE_ALARM_SUCCESS', false);
            }
        }
    },
    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
        selectedOrganizationBranch: {
            get() {
                return {
                    label: this.organizationBranchString(this.selectedOrganizationBranchValue),
                    value: this.selectedOrganizationBranchValue
                }
            },
            set(obj) {
                this.selectedOrganizationBranchValue = obj.value;
                if(this.selectedOrganizationBranch.value == null && this.selectedOrganizationBranch.label == 'Tất cả'){
                    this.loadAllAiFireAlarm()
                } else {
                    this.loadAiFireAlarm()
                }
            }
        }
    },
    methods: {
        addAIRes() {
            this.$router.push(`/user/ai-fire-alarm-create?branch_id=${this.selectedOrganizationBranchValue}`);
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        organizationBranchString(id) {
            let organizationBranch = this.organizationBranchOptions.find(x => x.value === id);
            return (organizationBranch && organizationBranch.label) || 'Chưa xác định';
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        getCamera() {
            return new Promise(resolve => {
                let that = this;
                let url = '/camera/all';
                that.$vs.loading();
                that.$crm.post(url).then((response) => {
                    that.cameraOptions = response.data
                    resolve(true);
                    that.$vs.loading.close();
                }).catch((err) => {
                    that.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
        },
        async initScreen() {
            await this.getCamera();
            let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
            if (branchList.length === 0) {
                return this.$vs.notify({
                    text: 'Hiện tại không tồn tại chi nhánh nào',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            this.organizationBranchOptions = branchList.map(item => {
                return {value: item.id, label: item.organizationBranchName}
            });
            this.organizationBranchOptions.unshift({value: null, label: 'Tất cả'});
            this.selectedOrganizationBranch = this.organizationBranchOptions[0];
            let branchId = this.$route.query.branch_id;
            let index = 0;
            if (branchId) {
                index = this.organizationBranchOptions.findIndex(item => item.value == branchId)
            }
            this.selectedOrganizationBranch = this.organizationBranchOptions[index > -1 ? index : 0];
            this.loadAiFireAlarm();
        },
        loadAiFireAlarm() {
            this.$vs.loading();
            this.selectedOrganizationBranchValue ?    this.$crm.post('/fire-detect/find-by-condition', {organizationBranchId: this.selectedOrganizationBranchValue}).then((response) => {
                this.aiFireAlarm = response.data;
                this.aiFireAlarm.forEach(x => {
                    let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
                    branchList.forEach(el => {
                        if (el.id == x.organizationBranchId) x.branchName = el.organizationBranchName
                    })
                    let camera = this.cameraOptions.find(cam => cam.id == x.cameraId);
                    if (camera) x.cameraName = camera.cameraName;
                })
                this.$vs.loading.close();
            }).catch(() => {
                this.$vs.loading.close();
                this.isShowErrorFunction = true;
            }) :  this.$crm.get('/fire-detect/find-all').then((response) => {
                this.aiFireAlarm = response.data;
                this.aiFireAlarmCheck = this.aiFireAlarm;
                this.aiFireAlarm.forEach(x => {
                    let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
                    branchList.forEach(el => {
                        if (el.id == x.organizationBranchId) x.branchName = el.organizationBranchName
                    })
                    let camera = this.cameraOptions.find(cam => cam.id == x.cameraId);
                    if (camera) x.cameraName = camera.cameraName;
                })
                this.$vs.loading.close();
            }).catch(() => {
                this.$vs.loading.close();
                this.isShowErrorFunction = true;
            });
        },
        loadAllAiFireAlarm() {
            this.$vs.loading();
            this.$crm.get(`/fire-detect/find-all`).then((response) => {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'));
                let result = response.data;
                // if (userInfo.organizationBranchId) result = result.filter(el => el.organizationBranchId == userInfo.organizationBranchId);
                this.aiFireAlarm = result;
                this.aiFireAlarmCheck = this.aiFireAlarm;
                console.log('result')
                this.aiFireAlarm.forEach(x => {
                    let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
                    branchList.forEach(el => {
                        if (el.id == x.organizationBranchId) x.branchName = el.organizationBranchName
                    })
                    let camera = this.cameraOptions.find(cam => cam.id == x.cameraId);
                    if (camera) x.cameraName = camera.cameraName;
                })
                this.$vs.loading.close();
            }).catch(() => {
                this.$vs.loading.close();
                this.isShowErrorFunction = true;
            });

        }

    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    },
}
</script>

<style scoped>

</style>
